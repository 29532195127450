import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Article from '../../components/Article';
import { h2 } from '../../components/Headings';
import CommonHero from '../../components/layout/CommonHero';
import Layout from '../../components/layout/Layout';
import Seo from '../../components/layout/Seo';

export default function KidsBraces() {
    return (
        <Layout>
            <Seo
                title="kids braces"
                description="Land Orthodontics is dedicated to bringing affordable braces and Invisalign to St Croix, VI."
            />
            <CommonHero title="kids braces" />
            <Article>
                <h1>Braces for Kids</h1>
                <p>
                    As your child begins to loose their baby teeth and permanent teeth come in, you
                    may begin to notice issues with their smile or bite. But have no fear! There is
                    a lot we can do to address these issues and move your child's teeth into their
                    optimal position while correcting their jaws and bite to the ideal alignment.
                </p>
                <p>
                    The American Association of Orthodontics recommends every child have a
                    professional orthodontic evaluation performed by age 7. We provide that
                    consultation free of charge to ensure that you as a parent understand exactly
                    what treatment (if any) may be necessary to correct your child's occlusion
                    (bite) and smile.
                </p>

                <article>
                    <StaticImage
                        src="../../images/services/kids/01.jpg"
                        alt="braces for kids"
                        loading="eager"
                        width={780}
                        height={780}
                        className="gatsby-img-box"
                    />
                    <div className="textBox">
                        <h2>PHASE ONE (INTERCEPTIVE) TREATMENT</h2>
                        <p>
                            While most of our pediatric patients begin treatment between 11 and 15,
                            it can sometimes be advantageous to begin a limited run of interceptive
                            treatment at an earlier age. Depending on the specific issues and
                            malocclusion of your child's teeth, your orthodontist may recommend
                            splitting their treatment into two stages.
                        </p>
                        <p>
                            Phase One treatment may involve braces for 6-12 months as a preliminary
                            treatment. In other cases, a fixed oral appliance may be use to expand
                            the upper arch and address crossbite. In completely different cases, we
                            may fit a removable appliance (similar to a retainer) to place pressure
                            on specific teeth to move them into alignment for the second phase of
                            treatment. Rule of thumb here: every child and every smile is different!
                            You can rest assure that we will work with you to customize your child's
                            treatment plan.
                        </p>
                    </div>
                </article>

                <article>
                    <div className="textBox">
                        <h3
                            css={css`
                                ${h2};
                            `}
                        >
                            WHAT TO EXPECT WITH BRACES
                        </h3>
                        <p>
                            Once your child is ready for braces, we will begin treatment with
                            diagnostic records, including photographs and x-rays, and potentially
                            spacers or separators (to allow space for molar bands). Following this,
                            your child will visit us for a 90 minute appointment to get their braces
                            put on. They may have a full set placed in one appointment or we may
                            split it up into two appointments if we need to address certain bite
                            issues first.
                        </p>
                        <p>
                            Following this appointment, it's very normal to experience some
                            discomfort and soreness, so we recommend taking your family's standard
                            over-the-counter pain relief medicine. We also encourage moms and dads
                            to treat their child to a milkshake or other cool treat afterward to
                            help sooth any aches the patient may feel. We're looking out for you,
                            kids!
                        </p>
                    </div>

                    <StaticImage
                        src="../../images/services/kids/02.jpg"
                        alt="braces for kids"
                        width={780}
                        height={780}
                        className="gatsby-img-box"
                    />
                </article>

                <article>
                    <StaticImage
                        src="../../images/services/kids/03.jpg"
                        alt="braces for kids"
                        width={780}
                        height={780}
                        className="gatsby-img-box"
                    />
                    <div className="textBox">
                        <h4
                            css={css`
                                ${h2};
                            `}
                        >
                            COMPLETING TREATMENT & ENTERING RETENTION
                        </h4>
                        <p>
                            The average treatment time in braces hovers around 24 months, during
                            which time we will be seeing you for periodic adjustments every 4-6
                            weeks. Once you enter final stages of perfecting your bite, we will be
                            placing you in finishing wires to stabilize your teeth in their new
                            position and prepare them for the retention stage post-braces.
                        </p>
                        <p>
                            Once we finally remove your braces, you're still not finished! We will
                            provide you with an upper and lower retainer to wear every night. This
                            retainer will keep your teeth in their new, perfect position and help to
                            continue to stabilize your new, healthy smile. We will see you
                            periodically (every 6-12 months) for follow-up visits just to make
                            certain your retainer still fits and that you are wearing it properly.
                        </p>
                    </div>
                </article>
            </Article>
        </Layout>
    );
}
